import { PropsWithChildren, ReactNode } from 'react';
import { Card, CardContent } from './ui/card';
import { AlertCircleIcon } from 'lucide-react';
import { SymbolIcon } from '@radix-ui/react-icons';

type SuspenseProps = {
  isFetching: boolean;
  isError: boolean;
};
function Loader({ children, isFetching, isError }: PropsWithChildren<SuspenseProps>): ReactNode {
  return (
    <Card className='h-full'>
      <CardContent className='h-full'>
        {isFetching && (
          <div className='flex items-center gap-4'>
            <SymbolIcon className='w-5 h-5 animate-spin' />
            <span className='text-lg'>Loading posts...</span>
          </div>
        )}

        {isError && (
          <div className='flex items-center gap-4 text-red-500 font-2xl'>
            <AlertCircleIcon className='w-5 h-5' />
            <span className='text-lg'>Error loading posts</span>
          </div>
        )}
        {children}
      </CardContent>
    </Card>
  );
}

export default Loader;
