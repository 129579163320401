import { useCompressPost } from 'services/postService';
import { DatabaseOptions, maxPostTextLength } from 'utils/post';
import { Button } from '~/ui/button';
import { DialogContent, DialogHeader, DialogTitle } from '~/ui/dialog';
import { Textarea } from '~/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/ui/select';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/ui/form';
import { LoaderIcon } from 'lucide-react';
import { Popover, PopoverTrigger, PopoverContent } from '~/ui/popover';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import toast from 'react-hot-toast';
import { useContext, useState } from 'react';
import WalletConnect from '~/wallet-connect';
import { useAccount } from 'wagmi';
import { CreatePostContext, formSchema } from '../providers/create-post-provider';

type CreatePostProps = {
  closeDialog: () => void;
  onSuccess: () => void;
};

function CreatePost({ closeDialog, onSuccess }: CreatePostProps) {
  const { mutation: compressionMutation, compressionResult, setCompressionResult } = useCompressPost();
  const { isConnected } = useAccount();

  function createPostSuccess() {
    onSuccess();
    onDialogClose();
  }

  const { submitPostToChain, submitPostToDB } = useContext(CreatePostContext);

  const [renderCompressButton, setRenderCompressButton] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      network: DatabaseOptions.Database,
      text: '',
    },
  });

  async function onCompressPost() {
    await form.trigger();
    if (form.formState.errors.text) {
      const formError = form.formState.errors.text.message;
      if (formError) toast.error(formError);
      return;
    }
    const text = form.getValues('text');

    compressionMutation.mutate({ data: text });
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    const { network } = values;

    if (network === DatabaseOptions.Database) {
      submitPostToDB(values.text, createPostSuccess);
    } else {
      if (!compressionResult) return;
      submitPostToChain(compressionResult.encoding, compressionResult.packedData, createPostSuccess);
    }
  }

  // @ts-ignore
  function onSubmitError(e) {
    const keys = Object.keys(e);
    if (keys.length > 0) {
      if (e.text) {
        toast.error(e.text.message);
      }
      return;
    }
  }

  function onDialogClose() {
    form.reset();
    form.clearErrors();
    setCompressionResult(undefined);
    setRenderCompressButton(false);
    closeDialog();
  }

  const enableSubmit = form.getValues('network') === DatabaseOptions.Database || (compressionResult && isConnected);

  function onDatabaseSelectValueChange(value: DatabaseOptions) {
    form.setValue('network', value);
    if (value === DatabaseOptions.Database) {
      setCompressionResult(undefined);
      setRenderCompressButton(false);
    } else {
      setRenderCompressButton(true);
    }
  }

  return (
    <DialogContent className='h-full'>
      <DialogHeader>
        <DialogTitle className='text-xl '>Create Post </DialogTitle>
        <div className='flex flex-col h-full gap-4 py-4'>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, onSubmitError)} className='flex flex-col h-full gap-y-6'>
              <FormField
                control={form.control}
                name='network'
                render={({ field }) => (
                  <FormItem key={field.value}>
                    <Select {...field} value={String(field.value)} onValueChange={onDatabaseSelectValueChange}>
                      <FormControl>
                        <SelectTrigger tabIndex={-1} className='w-full'>
                          <SelectValue className='text-xl ' placeholder='Select Network' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(DatabaseOptions).map((option) => (
                          <SelectItem className='text-lg' key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    {form.formState.errors.network && (
                      <FormMessage>{form.formState.errors.network.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='text'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        {...field}
                        tabIndex={-1}
                        className='font-sans text-lg'
                        rows={8}
                        placeholder='Post text'
                      />
                    </FormControl>
                    <div className='text-right text-gray-400 '>
                      {field.value.length} / {maxPostTextLength}
                    </div>
                  </FormItem>
                )}
              />
              {!compressionResult && renderCompressButton && (
                <FormDescription>
                  <div className='flex items-center gap-4'>
                    <Button onClick={onCompressPost} type='button'>
                      Compress Data
                    </Button>
                    <Popover>
                      <PopoverTrigger className='block'>
                        <Button type='button' className='flex' size={'icon'} variant={'link'}>
                          <QuestionMarkCircledIcon className='w-4 h-4' />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className='flex flex-col gap-4 overscroll-none touch-none'>
                        <div>Data will be compressed before being posted to the blockchain.</div>
                        <div>Compressing data before posting to the blockchain can save gas fees.</div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </FormDescription>
              )}
              {compressionResult && (
                <FormDescription>
                  <Popover>
                    <PopoverTrigger className='block'>
                      <Button type='button' className='flex' variant={'link'}>
                        Compressed Data
                        <QuestionMarkCircledIcon className='w-4 h-4' />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className='flex flex-col gap-4 overscroll-none touch-none'>
                      <div>{compressionResult.encoding}</div>
                      <div>Original: {compressionResult.dataSizeBeforeCompression} (bytes)</div>
                      <div>Compressed: {compressionResult.dataSizeAfterCompression} (bytes)</div>
                      <div>
                        Ratio:
                        {(
                          compressionResult.dataSizeBeforeCompression / compressionResult.dataSizeAfterCompression
                        ).toFixed(2)}
                      </div>
                    </PopoverContent>
                  </Popover>
                </FormDescription>
              )}
              {compressionMutation.isError && <FormMessage>{'Error compressing data'}</FormMessage>}
              {compressionMutation.isPending && (
                <div className='flex items-center gap-4'>
                  <FormLabel>Compressing...</FormLabel>
                  <LoaderIcon className='w-4 h-4 animate-spin' />
                </div>
              )}

              {renderCompressButton && <WalletConnect />}

              <div className='flex justify-between gap-4 mt-auto'>
                <Button type='button' variant={'destructive'} onClick={onDialogClose}>
                  Cancel
                </Button>
                <Button disabled={!enableSubmit} type='submit'>
                  Submit
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </DialogHeader>
    </DialogContent>
  );
}

export default CreatePost;
