'use client';

import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { PostPaginationType, usePosts, type Post } from '../../services/postService';

import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Dialog } from '~/ui/dialog';
import PostCard from './components/card';
import CreatePost from './components/create';
import PostNavigation from './components/navigation';
import { CreatePostContextProvider } from './providers/create-post-provider';
import Loader from '~/loader';

const defaultPostPaginationParams: PostPaginationType = { start: 0, limit: 25, order: 'desc', afterId: 0 };

const HomePage: React.FC = () => {
  const [postPaginationParams, setPostPaginationParams] = useQueryParams({
    start: withDefault(NumberParam, defaultPostPaginationParams.start, true),
    limit: withDefault(NumberParam, defaultPostPaginationParams.limit, true),
    order: withDefault(StringParam, defaultPostPaginationParams.order, true),
    afterId: withDefault(NumberParam, defaultPostPaginationParams.afterId, true),
  }) as unknown as [PostPaginationType, Dispatch<SetStateAction<PostPaginationType>>];

  const { data: postResponse, isError, isFetching } = usePosts(postPaginationParams);

  const [expandedPosts, setExpandedPosts] = useState<number[]>([]);

  const handleExpandPost = (id: number) => {
    setExpandedPosts((prev) => (prev.includes(id) ? prev.filter((p) => p !== id) : [...prev, id]));
  };

  const [isCreatePostOpen, setIsCreatePostOpen] = useState(false);

  return (
    <CreatePostContextProvider>
      <Dialog onOpenChange={(e) => setIsCreatePostOpen(e)} open={isCreatePostOpen}>
        <CreatePost
          onSuccess={() => {
            setPostPaginationParams(defaultPostPaginationParams);
          }}
          closeDialog={() => setIsCreatePostOpen(false)}
        />
        <Loader isFetching={isFetching} isError={isError}>
          {postResponse?.posts && postResponse.posts.length === 0 && <div>No posts found</div>}
          <div className='h-full overflow-scroll'>
            <div className='flex flex-col px-1 gap-y-2'>
              {postResponse?.posts &&
                postResponse?.posts.map((post: Post) => (
                  <PostCard
                    key={post.id}
                    post={post}
                    expanded={expandedPosts.includes(post.id)}
                    onToggleExpand={handleExpandPost}
                  />
                ))}
            </div>
          </div>
        </Loader>
        {/* WE NEED A TOS  */}
        {/* And other legal info */}
        <PostNavigation
          postPaginationParams={postPaginationParams}
          setPostPaginationParams={setPostPaginationParams}
          pageCount={postResponse?.pageCount || 0}
          openCreatePostDialog={() => setIsCreatePostOpen(true)}
        />
      </Dialog>
    </CreatePostContextProvider>
  );
};

export default HomePage;
