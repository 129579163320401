import { useRef, useState, useEffect } from 'react';
import { EnterFullScreenIcon, ExitFullScreenIcon } from '@radix-ui/react-icons';
import { dataStoreToString, encodingToString, maxPostTextLength, postDateFormatter } from 'utils/post';
import { Post } from 'services/postService';
import { Button } from '~/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '~/ui/card';

type PostProps = {
  post: Post;
  expanded: boolean;
  onToggleExpand: (id: number) => void;
};

function PostCard({ post, expanded, onToggleExpand }: PostProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > contentRef.current.clientHeight ||
          contentRef.current.scrollWidth > contentRef.current.clientWidth,
      );
    }
  }, [contentRef, post.data, expanded]);

  return (
    <>
      <Card key={post.id} className={`flex  flex-col ${expanded ? '' : 'max-h-80'}`}>
        <CardHeader className='px-4 py-2'>
          <CardTitle className='flex items-center justify-between'>
            <span className='flex gap-4 text-sm text-nowrap '>
              <span>{post.id}</span> <span>{postDateFormatter(new Date(post.date * 1000), false)}</span>
              <span>
                {encodingToString(post.encoding)} / {dataStoreToString(post.datastore)}
              </span>
            </span>
            {isOverflowing && !expanded && (
              <Button size={'icon'} variant={'link'} onClick={() => onToggleExpand(post.id)}>
                <EnterFullScreenIcon className='w-7 h-7 ' />
              </Button>
            )}
            {expanded && (
              <Button size={'icon'} variant={'link'} onClick={() => onToggleExpand(post.id)}>
                <ExitFullScreenIcon className='w-7 h-7 ' />
              </Button>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent ref={contentRef} className='flex overflow-hidden bg-gray-800 '>
          <span
            className='font-sans text-lg whitespace-pre-line'
            style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
          >
            {post.data}
          </span>
        </CardContent>
        <CardFooter className='pt-4 bg-gray-800'>
          <div className='flex items-center justify-end w-full text-gray-400'>
            <span>{post.data.length}</span> / <span>{maxPostTextLength}</span>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default PostCard;
