import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiURL } from './api';
import toast from 'react-hot-toast';
import { useState } from 'react';

export type PostsResponse = {
  posts: Post[];
  pageCount: number;
};

export type Post = {
  id: number;
  data: string;
  date: number;
  encoding: number;
  datastore: number;
};

export type CompressedPost = {
  packedData: string;
  encoding: number;
  date: number;
};

export type PostPaginationType = {
  start: number;
  limit: number;
  order: 'asc' | 'desc';
  afterId: number;
};

interface CreatePostParams {
  data: string;
}

export type CompressionResult = {
  packedData: CompressedPost['packedData'];
  encoding: CompressedPost['encoding'];
  dataSizeBeforeCompression: number;
  dataSizeAfterCompression: number;
};

const fetchPosts = async ({ queryKey }: { queryKey: any }): Promise<PostsResponse> => {
  const [_, { start, limit, order, afterId }] = queryKey;
  const response = await fetch(`${apiURL}/posts?start=${start}&limit=${limit}&order=${order}&afterId=${afterId}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchPostOnChain = async ({ queryKey }: { queryKey: any }): Promise<Post> => {
  const [_, hash] = queryKey;
  const response = await fetch(`${apiURL}/post-on-chain/blockhash/${hash}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const createPost = async (post: CreatePostParams): Promise<Post> => {
  const response = await fetch(`${apiURL}/post`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const compressPost = async (post: CreatePostParams): Promise<CompressionResult> => {
  const response = await fetch(`${apiURL}/compress-post`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  console.log('Compressed post');
  return response.json();
};

export const usePosts = ({ start, limit, order, afterId }: PostPaginationType) =>
  useQuery({
    queryKey: ['posts', { start, limit, order, afterId }],
    queryFn: fetchPosts,
    placeholderData: (previousData) => previousData,
  });

export const usePostOnChain = (hash?: string) =>
  useQuery({
    enabled: false,
    queryKey: ['post-on-chain', hash],
    queryFn: fetchPostOnChain,
    placeholderData: (previousData) => previousData,
  });

export const useCreatePost = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPost,
    onSuccess: () => {
      toast.success('Post created');
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useCompressPost = () => {
  const [compressionResult, setCompressionResult] = useState<CompressionResult>();

  const mutation = useMutation({
    mutationFn: compressPost,
    onSuccess: (result) => {
      toast.success('Post compressed');
      setCompressionResult({
        packedData: result.packedData,
        encoding: result.encoding,
        dataSizeBeforeCompression: result.dataSizeBeforeCompression,
        dataSizeAfterCompression: result.dataSizeAfterCompression,
      });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return { mutation, compressionResult, setCompressionResult };
};
